import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import * as Icon from 'react-bootstrap-icons';
import { useLocation } from "react-router-dom";
import logo from '../../assets/images/logos/logo-colored.webp'
import Offcanvas from 'react-bootstrap/Offcanvas';
// import bag from '../../assets/images/bag.png'
// import user from '../../assets/images/user.png'


const Header = () => {
  const location = useLocation();
  
  const { pathname } = location;
//   const [locationParam, setLocationParam] = useState([]);
  
//   useEffect(() => {
//     setLocationParam([]);
//     const locations = pathname.split('/');
//     //loop through the locations and set the locationParam
//     locations.forEach((location) => {
//       if (location !== "") {
//         setLocationParam((locationParam) => [...locationParam, location]);
//       }
//     })
//   }, [pathname]);
  
  const splitLocation = pathname.split("/");

  return (
    <>
      <Navbar expand="xl" className="" id="website-nav">
        <Container className='px-md-5 px-lg-4 '>
            <Navbar.Brand><Link
            className="flex-wrap navbar-brand d-flex align-items-baseline"
            to="/"
            >
            <img
                src={logo}
                alt="Logo"
                className="py-2 img-fluid logo-img"
            />
            </Link>
            </Navbar.Brand>
                
                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    <img
                        src={logo}
                        alt="Logo"
                        className="py-2 img-fluid"
                        style={{height: "45px"}}
                    />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe- text-work-sans website-np align-items-nav">
                    <Link to="/about-us" className={splitLocation[1] === "/about-us" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase pe-" : "pe- py-2 fs-zk-16 nav-link text-work-sans text-uppercase"} style={{fontWeight:"600"}}>About Us</Link>
                    <NavDropdown renderMenuOnMount={true} 
                        className={splitLocation[1] === "healthcare-digital-marketing-dubai" || splitLocation[1] === "e-prescription" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase sub-nav-menu" : "py-2 fs-zk-16 nav-link text-work-sans text-uppercase sub-nav-menu"} style={{fontWeight:"600"}}
                        title="What We Offer"
                        id={`offcanvasNavbarDropdown-expand-lg`}
                    >
                        <Link to="/mobile-app-development" className={splitLocation[1] === "mobile-app-development" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>App Development</Link>
                        <Link to="/web-development/" className={splitLocation[1] === "emr-solution-in-dubai" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}> Web Development </Link>
                        <Link to="/digital-marketing/" className={splitLocation[1] === "emr-solution-in-dubai" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}> Digital Marketing </Link>
                        <Link to="/healthcare-lab-solution" className={splitLocation[1] === "healthcare-lab-solution" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Healthcare Lab Solution</Link>
                        <Link to="/appointment-scheduling-solution" className={splitLocation[1] === "appointment-scheduling-solution" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Appointment Scheduling</Link>
                        <Link to="/e-prescription-solution" className={splitLocation[1] === "e-prescription-solution" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>E-prescription</Link>
                        <Link to="/medical-staff-management" className={splitLocation[1] === "/medical-staff-management" ? "active py-2 fs-zk-16 nav-link text-work-sans" : "py-2 fs-zk-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>Staff Management</Link>
                        
                    </NavDropdown>
                    <Link to="/who-we-serve" className={splitLocation[1] === "who-we-serve" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase pe-" : "pe- py-2 fs-zk-16 nav-link text-work-sans text-uppercase"} style={{fontWeight:"600"}}>Who We Serve</Link>
                    <Link to="/faq" className={splitLocation[1] === "faq" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase pe-" : "pe- py-2 fs-zk-16 nav-link text-work-sans text-uppercase"} style={{fontWeight:"600"}}>FAQs</Link>
                    <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase pe-" : "pe- py-2 fs-zk-16 nav-link text-work-sans text-uppercase"} style={{fontWeight:"600"}}>Contact Us</Link>
                    <Link to="/request-quote" className={splitLocation[1] === "request-quote" ? "active py-2 fs-zk-16 nav-link text-work-sans text-uppercase" : "py-2 fs-zk-16 nav-link text-work-sans text-uppercase"} style={{fontWeight:"600"}}>
                        <button className="btn btn-zk-primary btn-sm text-uppercase text-work-sans fs-zk-14 rounded30 px-3 py-2 shadow" style={{fontWeight:"700"}}>Request A Quote</button>
                    </Link>
                </Nav>
            </Offcanvas.Body>
            </Navbar.Offcanvas>  
        </Container>
        </Navbar>
    </>
  )
}

export default Header