import React,{Suspense} from 'react'
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/style1.css'
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HealthcareLabSolution from './pages/HealthcareLabSolution';

const Home = React.lazy(() => import('./pages/Home'));
const About = React.lazy(() => import('./pages/About'));
const WhoWeServe = React.lazy(() => import('./pages/WhoWeServe'));
const EPrescription = React.lazy(() => import('./pages/EPrescription'));
const WebDevelopment = React.lazy(() => import('./pages/WebDevelopment'));
const Appointment = React.lazy(() => import('./pages/Appointment'));
const StaffManagement = React.lazy(() => import('./pages/StaffManagement'));
const Contact = React.lazy(() => import('./pages/Contact'));
const RequestDemo = React.lazy(() => import('./pages/RequestDemo'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const FAQs = React.lazy(() => import('./pages/Faq'))
const AppDev = React.lazy(() => import('./pages/AppDev'))
const DigitalMarketing = React.lazy(() => import('./pages/DigitalMarketing'))
const NotFound = React.lazy(() => import('./pages/404'))
 
const App = () => {
    return (
        <>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<About />}/>
                <Route path="/who-we-serve" element = {<WhoWeServe/>} />
                <Route path="/e-prescription-solution" element = {<EPrescription/>} />
                <Route path="/healthcare-lab-solution" element = {<HealthcareLabSolution/>} />
                <Route path="/web-development/" element = {<WebDevelopment/>} />
                <Route path="/digital-marketing/" element = {<DigitalMarketing/>} />
                <Route path="/appointment-scheduling-solution" element = {<Appointment/>} />
                <Route path="/medical-staff-management" element = {<StaffManagement/>} />
                <Route path="/mobile-app-development" element = {<AppDev/>} />
                <Route path='/contact-us' element={<Contact />}/>
                <Route path='/request-quote' element={<RequestDemo />}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/faq" element={<FAQs/>} />
                <Route path="*" element={<NotFound/>}  />

            </Routes>
            <ToastContainer 
                position="bottom-left"
                autoClose={5000}
                />
        </Suspense>
        
        </>
    )
}

export default App
