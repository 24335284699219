import React from 'react'
import logocolored from '../../assets/images/logos/logo-colored.webp'
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin } from 'react-bootstrap-icons';


const Footer = () => {
    return (
        <>
            <footer className="footer" id="website-responsive">
                <div className="container-zk px-md-5" style={{zIndex:"2", position:"relative"}}>
                    <div className="bg-zk-primary px--md5  py-5 rounded20">  
                        <div className="fs-zk-30 container-zk px-md-5 px-0">
                            <div className="text-lato lh-1-2 text-center text-white" style={{fontWeight:"600"}}>
                                Learn How We're helping brilliant minds to explore their way.
                            </div>
                            <div className="text-center mt-4">
                                <Link to="/request-quote" className="btn btn-zk-white btn-sm text-uppercase fs-zk-18 rounded30 shadow px-4 py-2" style={{fontWeight:"500"}}>Request a Quote</Link>
                            </div>
                        </div>
                    </div>  
                </div>
                <div className='' style={{marginTop:"-130px"}}>
                    <div className="footer-bg">
                        <div className="container-zk px-md-5 footer-links-section">
                            <div className="row gx-md-5">
                                <div className='col-md-4'>
                                    <div className="">
                                        <img src={logocolored} alt="" className="img-fluid" style={{width:"200px"}}/>
                                        <div className='fs-zk-18 mt-2 mb-5'>
                                        With expertise in developing innovative and user-friendly web & mobile apps, icarepro helps businesses and startups across many regions to thrive in the dynamic landscape. 
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-8'>
                                    <div className="row gx-md-4">
                                        <div className='col-md-4 mb-4'>
                                            <div className="footer-links">
                                                <h3 className="text-oswald fs-zk-22 text-uppercase text-zk-primary" style={{fontWeight:"600"}}> Services
                                                </h3>
                                                <ul className="list-unstyled pt-2 lh-lg">
                                                    <li><Link to="/mobile-app-development" className='text-decoration-none text-black fs-zk-18'>App Development</Link></li>
                                                    <li><Link to="/web-development" className='text-decoration-none text-black fs-zk-18'>Web Development</Link></li>
                                                    <li><Link to="/digital-marketing" className='text-decoration-none text-black fs-zk-18'> Digital Marketing </Link></li>
                                                    <li><Link to="/appointment-scheduling-solution" className='text-decoration-none text-black fs-zk-18'>Appointment Scheduling</Link></li>
                                                    <li><Link to="/e-prescription-solution" className='text-decoration-none text-black fs-zk-18'>E-prescription</Link></li>
                                                    <li><Link to="/medical-staff-management" className='text-decoration-none text-black fs-zk-18'>Staff Management</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-4  mb-4'>
                                            <div className="footer-links">
                                                <h3 className="text-oswald fs-zk-22 text-uppercase text-zk-primary" style={{fontWeight:"600"}}>Quick Links</h3>
                                                <ul className="list-unstyled pt-2 lh-lg">
                                                    <li><Link to="/about-us" className='text-decoration-none text-black fs-zk-18'>About Us</Link></li>
                                                    <li><Link to="/request-quote" className='text-decoration-none text-black fs-zk-18'>Request Quote</Link></li>
                                                    <li><Link to="/faq" className='text-decoration-none text-black fs-zk-18'>FAQ</Link></li>
                                                    <li><Link to="/contact-us" className='text-decoration-none text-black fs-zk-18'>Contact Us</Link></li>
                                                    <li><Link to="/privacy-policy" className='text-decoration-none text-black fs-zk-18'>Privacy Policy</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-4 mb-4'>
                                            <div className="footer-links">
                                                <h3 className="text-oswald fs-zk-22 text-uppercase text-zk-primary" style={{fontWeight:"600"}}>Contact us</h3>
                                                <ul className="list-unstyled pt-2 text-black fs-zk-18">
                                                    <li className='lh-lg'>Email: <a href="mailto:care@icareprotech.com" className='text-decoration-none text-black fs-zk-18'>care@icareprotech.com</a></li>
                                                </ul>
                                                <div className="flex-wrap d-flex mx-auto mt-3 ">
                                    {/* <div className="social-links py-2 text-center">
                                        <a href="https://www.facebook.com/icareprotechnologies" className="text-decoration-none text-black" target="_black" >
                                        <Facebook size={26} className="mx-2 home-social-link"/>
                                        </a>
                                        <a href="https://instagram.com/icareprotechnologies" className="text-decoration-none text-black" target="_black" >
                                        <Instagram size={26} className="mx-2 home-social-link"/>
                                        </a>
                                        <a href="https://www.linkedin.com/company/icare-pro-technologies-ltd/about/?viewAsMember=true" className="text-decoration-none text-black" target="_black" >
                                        <Linkedin size={26} className="mx-2 home-social-link"/>
                                        </a>
                                    </div> */}
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="container-zk border-gray2">
                                <div className="row">
                                    <div className="col-md-12 pt-2">
                                        <div className="all-right text-dark text-center pt-3">
                                            <p className='fs-zk-14'>&copy; 2024 All Rights Reserved.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer