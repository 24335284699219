import React from 'react'
import loadingVideo from '../assets/images/loading.webm';


const Loading = () => {
  return (
    <div>
         <div className="loading-wrapper">
            <video src={loadingVideo} className="img-fluid" alt="icarepro loading" autoPlay muted loop="true" type="video/webm" />
          </div>
    </div>
  )
}

export default Loading