import React from 'react'
import {Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import bgimg from '../../assets/images/lab-solution/middle-bg.jpg'

const TransformingLabTesting = () => {
  return (
    <div id='website-responsive'>
        <div className='my-4 py-md-5 py-4 bg-img px-3' style={{ backgroundImage:`url(${bgimg})`}}>
        <center>
            <div className='py-3'>
                <div className='fs-zk-40 lh-sm text-lato text-white lh-1-2' style={{fontWeight:"500"}}>
                    Transforming Lab Testing into a Click Away
                </div>
            </div>
 
            {/* <div className='py-md-4 pt-2'>
                <Link to="/request-demo"  className='btn-zk-white rounded30 btn-sm text-uppercase me-md-4 me-2 px-md-3 px-2 py-1 rounded10 py-md-2 my-1 rounded-20 shadow-zk fs-zk-18 ' style={{fontWeight:'600',  boxShadow:'1px 1px 6px 0 rgba(0,0,0,.4)'}}>
                    <text className='text-mulish fw-700 fs-ss-20' style={{ padding:'12px 14px',}}>
                        Get In Touch
                    </text>
                </Link>
            </div> */}

            <div className='mt-md-3 mt-2'>
                <Link to="/contact-us" className='btn btn-zk-white btn-sm text-uppercase me-md-4 me-2 px-md-4 px-3 py-1 rounded10 py-md-2 my-1 rounded-20 shadow-zk fs-zk-18' style={{fontWeight:"500"}}>Get In Touch</Link>
            </div>
            </center>
        </div>
    </div>
  )
}

export default TransformingLabTesting