import React,{useEffect} from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import banner from '../assets/images/lab-solution/banner.webp'
import banner_mb from '../assets/images/lab-solution/banner_mb.png'

import img1 from '../assets/images/lab-solution/img1.webp'
import img2 from '../assets/images/lab-solution/img2.webp'
import img3 from '../assets/images/lab-solution/img3.webp'
import img4 from '../assets/images/lab-solution/img4.webp'

import TransformingLabTesting from '../components/HealthcareLabSolution/TransformingLabTesting'
import BenefitsOfDigitalLab from '../components/HealthcareLabSolution/BenefitsOfDigitalLab'
import { Link } from 'react-router-dom'
import {Helmet} from 'react-helmet-async'

const HealthcareLabSolution = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (

    <>
      <Helmet>
          <title>Healthcare Online Lab Solution | icarepro</title> 
          
          <meta property="og:site_name" content="icarepro" />
          <meta property="og:image" content="https://icarepro.ae/og.png" />
          <meta property='og:image:width' content='1200' />
          <meta property='og:image:height' content='630' />
          <meta property="og:image:secure_url" content="https://icarepro.ae/og.png" />
          
      </Helmet>
      <Header />

      <section>
            <div className='banner-section'>
              <img className="img-fluid d-none d-sm-none d-md-block" alt='healthcare online lab solution' src={banner} style={{ minWidth:"100%"}}/>
              <img className="img-fluid d-md-none d-sm-block" alt='healthcare online lab solution' src={banner_mb} style={{ minWidth:"100%"}}/>
              <div className='container-zk'>
                <div className="">
                  <div className='page-banner-title'>
                    <h1>Making Healthcare <br/>Simple & Accessible for Patients</h1>
                      <div className='page-banner-desc lh-1-2' style={{fonWeight:"500"}}>
                      icarepro app offers the most comprehensive digital health<br/> management solution for providers, caregivers and doctors. 
                      </div>
                      <div className='page-banner-btn'>
                      <Link to="/request-quote" className="btn-zk-white-banner text-uppercase fs-zk-14 px-md-4 py-md-3 px-3 py-2 shadow">Request A Quote</Link>
                      </div>
                  </div>
                </div>
              </div>
            </div>
        </section>

        <section className='container-zk pt-5 pb-md-3' id="website-responsive">
            <div className='row align-items-center pt-md-5 pb-3 gx-md-5'>
                <div className='col'>
                    <h2 className='text-gotham text-zk-primary lh-1-2 fs-zk-40' style={{fontWeight:"700"}}><span className='text-zk-black'> Revolutionizing </span> Healthcare
                        <hr className="border-zk-primary2 mt-2 w80px"/>

                    </h2>
                    <div className='fs-zk-18 mt1'>
                      Healthcare has always been a critical aspect of human life, and technological advancements have significantly impacted the sector. Digital health solutions have gained much traction in recent years, and our digital lab solution is one such innovative technology that aims to simplify and make healthcare accessible to everyone. Our solution provides both an admin and patient portal, enabling patients to select and buy any test they need and view their lab results on their accounts.
                    </div>
                </div>
            </div>
        </section>
        <section id="website-responsive">
            <div className="container-zk px-md-5 px-0 py-md-5">
                <div className="row align-items-center gy-4">
                    <div className="col-lg-5 col-12 d-md-block d-sm-none d-none">
                        <img src={img1} className="img-fluid w-100" alt='icarepro' />   
                    </div>

                    <div className='col-lg-7 col-12'>
                        <div className="px-md-5 px-2">
                            <div className="align-self-center row">
                                <div className='col-12'>
                                    <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                                      Digital Lab Solution <br/> <span className='text-zk-primary'>The Future of Healthcare</span>
                                      <hr className="border-zk-primary2 mt-2 w80px"/> 
                                    </h2>
                                    <div className='fs-zk-18 pt-1 pe-1'>
                                      In traditional healthcare systems, patients have to wait for hours to get their lab results. However, with our digital lab solution, patients can instantly view their lab results on their accounts. This feature is especially useful for people who live far from healthcare facilities or have mobility issues. Our digital lab solution allows patients to view their lab results from anywhere, at any time. Additionally, it enables healthcare providers to access patients’ results promptly, leading to better patient outcomes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 mx-auto d-block d-sm-block d-md-none mb-5">
                        <img src={img1} className="img-fluid" alt='icarepro' />   
                    </div>
                </div>
            </div>
        </section>

        <section id="website-responsive">
            <div className="container-zk px-md-5 px-0 py-md-5">
                <div className="row align-items-center gy-4">

                    <div className='col-lg-7 col-12'>
                        <div className="px-md-5 px-2">
                            <div className="align-self-center row">
                                <div className='col-12'>
                                    <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                                      Admin Portal <br/> <span className='text-zk-primary'>Streamlining Healthcare</span>
                                      <hr className="border-zk-primary2 mt-2 w80px"/> 
                                    </h2>
                                    <div className='fs-zk-18 pt-1 pe-1'>
                                      Our admin portal is designed to streamline healthcare processes. It enables healthcare providers to efficiently manage their laboratories, tests, and results. It enables healthcare providers to receive patient test requests, assign tests to laboratories, and view lab results. The portal also provides real-time analytics, enabling healthcare providers to monitor the performance of their laboratories and tests. Our admin portal ensures that healthcare providers can manage their services efficiently, leading to improved patient outcomes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-5 col-11">
                        <img src={img2} className="img-fluid w-100" alt='icarepro' />   
                    </div>

                </div>
            </div>
        </section>

        <TransformingLabTesting/>

        <section id="website-responsive">
            <div className="container-zk px-md-5 px-0 py-md-5">
                <div className="row align-items-center gy-4">
                    <div className="col-lg-5 col-12 d-md-block d-sm-none d-none">
                        <img src={img3} className="img-fluid w-100" alt='icarepro' />   
                    </div>
 
                    <div className='col-lg-7 col-12'>
                        <div className="px-md-5 px-2">
                            <div className="align-self-center row">
                              {/* <div className='col-12'>
                                    <hr className="border-zk-primary w80px"/>
                                </div> */}
                                <div className='col-12'>
                                    <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                                      Patient Portal <br/> <span className='text-zk-primary'>Empowering Patients</span>
                                      <hr className="border-zk-primary2 mt-2 w80px"/> 
                                    </h2>
                                    <div className='fs-zk-18 pt-1 pe-1'>
                                      Our patient portal is designed to empower patients by providing them with access to their lab results. Patients can select and purchase any test they need, view their results on their accounts, and share them with their healthcare providers. The patient portal also provides educational materials that help patients understand their lab results. Patients can use the patient portal to track their health progress and take preventive measures to improve their health outcomes.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-11 mx-auto mx-auto d-block d-sm-block d-md-none mb-5">
                        <img src={img3} className="img-fluid" alt='icarepro' />   
                    </div>
                </div>
            </div>
        </section>

        <section id="website-responsive">
            <div className="container-zk px-md-5 px-0 py-md-4">
                <div className="row align-items-center gy-4">

                    <div className='col-lg-7 col-12'>
                        <div className="px-md-5 px-2">
                            <div className="align-self-center row">
                                <div className='col-12'>
                                    <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                                      Accessibility The Key to <br/> <span className='text-zk-primary'>Better Healthcare </span>
                                      <hr className="border-zk-primary2 mt-2 w80px"/> 
                                    </h2>
                                    <div className='fs-zk-18 pt-1 pe-1'>
                                      Our digital lab solution is designed to make healthcare accessible to everyone. Accessibility is a significant barrier to healthcare, and our solution aims to bridge that gap. Our digital lab solution allows patients to access healthcare services from the comfort of their homes, eliminating the need to visit healthcare facilities physically. Our solution provides patients with the flexibility to select and purchase any test they need, making healthcare more personalized and convenient.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5 col-12 text-center">
                        <img src={img4} className="img-fluid " alt='icarepro' style={{width:"62%"}}/>   
                    </div>
                </div>


                <div className="row align-items-center gy-4 mt-3 px-md-5 px-2 py-4">            
                  <div className='col-12'>
                      <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                        Security - <span className='text-zk-primary'>Ensuring Patient Privacy</span>
                        <hr className="border-zk-primary2 mt-2 w80px"/> 
                      </h2>
                      <div className='fs-zk-18 pt-1 pe-1'>
                        Patient privacy is critical to healthcare, and our digital lab solution ensures that patients’ data is secure. Our solution complies with all data protection regulations, ensuring patient data is encrypted and stored securely. Patients have complete control over their data and can choose to share their lab results with healthcare providers or keep them private. Our digital lab solution assures patients that their data is secure and protected.
                      </div>
                  </div>
                </div>
                

            </div>
        </section>

        <BenefitsOfDigitalLab/>
 
        <section className='container-zk  py-5' id="website-responsive">
            <div className='row align-items-center pt-md-3 pb-3 gx-md-5'>
                <div className='col'>
                    <h3 className='text-gotham text-zk-primary lh-1-2 fs-zk-40' style={{fontWeight:"700"}}>
                      <span className='text-zk-black'> 
                        The Future of </span> Healthcare
                      <hr className="border-zk-primary2 mt-2 w80px"/>
                    </h3>
                    <div className='fs-zk-18 mt-1'>
                      Digital health solutions have tremendous potential to revolutionize healthcare in the coming years. With technological advancements, we can expect more innovative solutions that make healthcare simpler, more accessible, and more personalized. From telemedicine to wearable devices and mobile applications, digital health solutions are transforming the healthcare landscape. Our digital lab solution is just the beginning, and we believe it is a small step toward a brighter future in healthcare.
                    </div>


                    <h3 className='text-gotham text-zk-primary lh-1-2 fs-zk-40 mt-5' style={{fontWeight:"700"}}>
                      <span className='text-zk-black'> 
                        The Game </span>Changing Effect
                      <hr className="border-zk-primary2 mt-2 w80px"/>
                    </h3>
                    <div className='fs-zk-18 mt-1'>
                      Having reliable and automated lab management software is essential to the continued growth of any lab business. Labs, patients, and referring doctors demand a scalable lab business management solution that keeps them connected in terms of ease, speed, and accuracy. Your lab is unlikely to prosper or grow without timely insights and intelligence. 
                    </div>

                    <div className='fs-zk-18 pt-3'>
                      Our digital lab solution is a game-changer in the healthcare sector. It offers several benefits, including simplifying healthcare processes, empowering patients, and ensuring patient privacy. It has been designed to ensure that healthcare is more personalized and convenient, enabling patients to access healthcare services from the comfort of their homes. We believe that the future of healthcare is digital and are committed to creating innovative solutions that improve patient outcomes and transform the healthcare sector while ushering in a new era of healthcare, one that is more efficient, accessible, and patient-centric.
                    </div>

                </div>
            </div>
        </section>


    <div style={{ overflow:'hidden'}}>
        {/* <MakingHealthCare/> */}
        {/* <RevolutionizingHealthcare/> */}
        {/* <PatientPortalAndAccessibility/> */}
        {/* <BenefitsOfDigitalLab/> */}
        {/* <FutureOfHealthcare/> */}
    </div>

    <Footer/>
    </>
  )
}

export default HealthcareLabSolution