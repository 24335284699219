import React from 'react'
// import bgimg from '../../assets/images/lab-solution/Online-Lab-Solutions-Middle-Bg1-v1.jpg'
import { Col, Row } from 'reactstrap'
import { FaCircle, FaDotCircle } from 'react-icons/fa';


const BenefitsOfDigitalLab = () => {
  return (
    <section className='online-lab-bg' id='website-responsive'>
        {/* <div className='online-lab-bg py-md-5 py-4'> */}
        <div className='container-fluid py-md-2 py-4'>
            {/* <Row className='py-md-5'> */}
            <div className="row align-items-center py-md-5 px-md-5">

                <div className='col-6 d-none d-md-block'>
                </div>
                {/* <Col md='6' className=''></Col> */}

                <div className='col-12 col-md-6 px-lg-5'>
                {/* <Col md='6' className=''> */}
                    <div className=''>
                        <div className='pb-md-0 pb-1'>
                            <h2 className='fs-zk-40 lh-sm text-lato' style={{fontWeight:"700"}}> 
                                Benefits of Our <br/> Digital Lab Solutions
                                {/* <hr className="border-zk-primary2 mt-2 w80px"/>  */}
                            </h2>
                        </div>

                        <div className='py-md-4 pb-md-3 pb-3'>
                            <div className='fs-zk-18 pt-3 text-ss-black lh-1-6'>
                                Our digital lab solution offers several benefits that make it an ideal choice for healthcare providers and patients:
                            </div>
                        </div>

                        <div className='row'>
                        {/* <Row> */}
                            <div className='col-11 col-md-6' >
                                {/* <ul className='fs-ss-16 text-mulish fw-700 text-ss-black lh-1-6' style={{ marginBottom:'8px', listStyleType:'circle', listStyleImage:()=><FaCircle/> }}> */}
                                <ul className='fs-zk-16 fw-500 text-ss-black lh-1-6'>    
                                    <li className='pb-2' style={{ }}>Go Paperless</li> 
                                    <li className='pb-2'>Streamlined Workflow</li> 
                                    <li className='pb-'>Patient Engagement</li> 
                                </ul>
                            </div>

                            <div className='col-11 col-md-6'>
                                <ul className='fs-zk-16 fw-500 text-ss-black lh-1-6'>
                                    <li className='pb-2'>Faster Turnaround Times</li> 
                                    <li className='pb-2'>Increased Patient Satisfaction</li> 
                                    <li className='pb-2'>Health Management</li> 
                                </ul>
                            </div>
                        {/* </Row> */}
                        
                           
                        </div>
                    </div>
                {/* </Col> */}
                </div>
            </div>
        </div>
    </section>
  )
}

export default BenefitsOfDigitalLab